import * as React from "react"
import MenuMobile from "../components/menu/menu-mobile"
import Menu from "../components/menu/menu"
import DownloadPopup from "../components/download-popup"
import Footer from "../components/footer"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

// markup
const NotFoundPage = () => {
  const [showDownloadPopup, setShowDownloadPopup] =
    React.useState<boolean>(false)

  return (
    <>
      <DownloadPopup
        onCloseClick={() => setShowDownloadPopup(false)}
        show={showDownloadPopup}
      />

      <div className='app-container'>
        <MenuMobile onDownloadClick={() => setShowDownloadPopup(true)} />
        <Menu onDownloadClick={() => setShowDownloadPopup(true)} />

        <main>
          <div className='flex flex-col justify-center items-center hero container-404'>
            <h1 className='text-[24px] xl:text-[50px] text-white font-bold mb-10'>
              Oops! Page Not Found
            </h1>
            <Link to='/' className='go-home-btn text-[18px] xl:text-[23px]'>
              GO TO HOME
            </Link>
            <div className='img-404'>
              <StaticImage src='../images/404.png' alt='404' />
            </div>
          </div>
        </main>

        <Footer />
      </div>
    </>
  )
}

export default NotFoundPage
